import React, { useState } from "react"

import Layout from "../templates/Page"
import Footer from "../components/ContentBuilder/Footer"
import Header from "../components/ContentBuilder/Header"
import MyBoards from "../components/MyBoards"
import Subscribe from "../components/Footer/subscribe"

const MyBoardViewAllPage = () => {
  const [tabSelected, setTabSelected] = useState("my-boards")

  return (
    <Layout hideHeader>
      <Header isLogin />
      <div className="container">
        <div className="d-none d-sm-flex justify-content-center align-items-center wrap-section mt-30 mb-30">
          <div className="product-name-title">Welcome Josiah!</div>
        </div>
        <div className="wrap-section d-sm-flex mt-30 mb-30 row border-bottom-cell-np">
          <div
            className={
              tabSelected === "my-boards"
                ? "cursor-pointer my-board-section active w-50p"
                : "cursor-pointer my-board-section w-50p"
            }
            onClick={() => setTabSelected("my-boards")}
          >
            <div className="my-board-title">
              <div className="my-board-text mr-15">My Boards</div>
              <div className="my-board-icon">
                <img src="/img/drak-plus.svg" />
              </div>
            </div>
          </div>
          <div
            className={
              tabSelected !== "my-boards"
                ? "cursor-pointer my-board-section active w-50p"
                : "cursor-pointer my-board-section w-50p"
            }
            onClick={() => setTabSelected("my-quotes")}
          >
            <div className="my-board-title">
              <div className="my-board-text mr-15">My Quotes</div>
            </div>
          </div>
        </div>
        {tabSelected === "my-boards" && <MyBoards size="2" />}

        <Subscribe customClass="mt-15"></Subscribe>
      </div>
    </Layout>
  )
}

export default MyBoardViewAllPage
